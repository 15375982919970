import React, { useState } from 'react';
import '../App.scss';
import FooterBar from './FooterBar';
import checkScoreAPI from '../helpers/checkScoreAPI';

const ScoreChecker: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [score, setScore] = useState<number | null>(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleCheckScore = async () => {
    setError('');
    setScore(null);
    setLoading(true);

    // Validacija unosa broja telefona
    const phonePattern = /^(?:\+381|381|0)6(?:[0-9]\s?\d{6,7}|\d{7,8})$/;
    if (!phonePattern.test(phoneNumber)) {
      setLoading(false);
      setError('Unesite validan broj telefona.');
      return;
    }

    try {
      // Pozivamo API preko helper funkcije
      const response = await checkScoreAPI(phoneNumber);
        console.log('response:',response)
      if (response && typeof response === 'object' && 'score' in response) {
        setScore(response.score);
      } else {
        setError('Nije moguće dobiti rezultat. Pokušajte ponovo.');
      }
    } catch (err) {
      console.error('Došlo je do greške:', err);
      setError('Došlo je do greške. Pokušajte ponovo.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="form-container">
      <form className="form" onSubmit={(e) => e.preventDefault()}>
        <h2>Proveri ukupan broj poena</h2>
        <label htmlFor="phone">Unesite broj telefona:</label>
        <input
          type="text"
          id="phone"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          placeholder="Broj telefona"
        />
        {error && <p className="error">{error}</p>}
        <button type="button" className="submit-button" onClick={handleCheckScore} disabled={loading}>
          {loading ? 'Provera...' : 'Proveri'}
        </button>
        {score !== null && (
          <p className="success">
            Vaš ukupan broj poena je: <strong>{score}</strong>
          </p>
        )}
      </form>
      <FooterBar />
    </div>
  );
};

export default ScoreChecker;

import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import '../App.scss';

const NavigationBar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavItemClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    targetId: string
  ) => {
    event.preventDefault();

    // Ako nismo na početnoj stranici, prvo navigiramo na "/"
    if (location.pathname !== '/') {
      navigate('/');
      setTimeout(() => {
        scrollToTarget(targetId);
      }, 50);
    } else {
      scrollToTarget(targetId);
    }

    // Zatvaranje menija na mobilnoj verziji
    closeMobileMenu();
  };

  const scrollToTarget = (targetId: string) => {
    const targetElement = document.querySelector(targetId);
    if (targetElement) {
      let yOffset = -70; // Default offset
      if (targetId === '#lista-dobitnika') {
        yOffset = window.innerWidth <= 991 ? -500 : -150;
      }

      const y = targetElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  const closeMobileMenu = () => {
    const navbarToggler = document.querySelector('.navbar-toggler') as HTMLElement;
    const navbarCollapse = document.querySelector('.navbar-collapse') as HTMLElement;

    if (navbarToggler && navbarCollapse && navbarCollapse.classList.contains('show')) {
      navbarToggler.click(); // Aktiviramo Bootstrap logiku za zatvaranje
    }
  };

    return (
      <Navbar bg="primary" variant="dark" expand="lg" sticky="top" className="custom-navbar">
        <Container>
          {/* Tekst za mobilnu verziju */}
          <div
            className="navbar-brand-text d-flex flex-column align-items-start d-lg-none d-md-none"
            onClick={() => navigate('/')} // Dodajemo navigaciju na glavnu rutu
            style={{ cursor: 'pointer' }} // Dodajemo stil za pokazivač
          >
            <h1 className="text-uppercase mb-0 zgrabi-text">
              ZGRABI<span className="ultra-text" style={{ marginLeft: '2%' }}>ULTRA</span>
            </h1>
            <h1 className="text-uppercase zgrabi-text">NAGRADE</h1>
          </div>
    
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="ms-auto" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
            <Nav className="nav-links">
              <Nav.Link
                href="#mehanizam"
                className="nav-link"
                onClick={(e) => handleNavItemClick(e, '#mehanizam')}
              >
                NAGRADE
              </Nav.Link>
              <Nav.Link
                href="#lista-dobitnika"
                className="nav-link"
                onClick={(e) => handleNavItemClick(e, '#lista-dobitnika')}
              >
                LISTA DOBITNIKA
              </Nav.Link>
              <Nav.Link
                href="#pravila-kontakt"
                className="nav-link"
                onClick={(e) => handleNavItemClick(e, '#pravila-kontakt')}
              >
                PRAVILA
              </Nav.Link>
              <Nav.Link
                href="#pravila-kontakt"
                className="nav-link"
                onClick={(e) => handleNavItemClick(e, '#pravila-kontakt')}
              >
                KONTAKT
              </Nav.Link>
              <Nav.Link
                  className="nav-link"
                  onClick={() => navigate('/proveri-skor')}
              >
              PROVERI SKOR
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );    
};

export default NavigationBar;

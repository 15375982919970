import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import '../App.scss';

const BottomSection: React.FC = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const navigate = useNavigate(); // Koristimo useNavigate za navigaciju

  useEffect(() => {
    // Proverava širinu ekrana
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 575); // Mobilna verzija za ekrane ≤768px
    };

    handleResize(); // Inicijalna provera
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <section id="zapocni-kviz" className="quiz-bottom-section py-5 pb-0 text-white">
      <Container>
        {isMobile ? (
          // Mobilna verzija
          <Row className="text-center mt-5">
            <Col lg={12} className="d-flex flex-column align-items-center">
              <div className="image-with-text">
                <img
                  src="../kviz_mobile_bottom4.png"
                  alt="Rosa Bottles"
                  className="img-fluid"
                />
                <div className="text-overlay top-left">
                  <h2 className="font-uppercase2">
                    Nagradni konkurs je otvoren samo za osobe starije od 18 godina koje su. U
                    Nagradnom konkursu učestvuju svi ukusi Ultra Energy limenki od 0,25L i 0,5L.
                    Mehanika učešća podrazumeva kupovinu minimum jedne Ultra Energy limenke uz
                    fiskalni račun plaćen u Mega Maxi, Maxi i Shop&Go prodavnicama sa PFR kodom,
                    kako je opisano na web stranici www.ultrachallenge.rs. Glavna nagrada je
                    zimovanje na Kopaoniku uz kurs skijanja i sankanja, iPhone 15 i oprema za
                    skijanje. Za sve detalje o nagradama i uslovima i odredbama nagradnog konkursa
                    posetite web stranicu www.ultrachallenge.rs. Fotografije poklona su ilustrativnog
                    karaktera. Pažljivo pročitajte pravila nagradnog konkursa objavljena na web
                    stranici.
                  </h2>
                </div>
                {/* <div className="step-container">
                  <button
                    className="quiz-button"
                    onClick={() => navigate('/prijava')} // Navigacija na novu rutu
                  >
                    <p className="pokreni-text">POKRENI KVIZ</p>
                  </button>
                </div> */}
              </div>
            </Col>
          </Row>
        ) : (
          // Desktop verzija
          <Row className="text-center mt-5">
            <Col lg={12} className="d-flex flex-column align-items-center">
              <div className="image-with-text">
                <img
                  src="../kviz-tekst4.png"
                  alt="Rosa Bottles"
                  className="img-fluid"
                />
                <div className="text-overlay top-left">
                  <h2 className="font-uppercase2">
                    Nagradni konkurs je otvoren samo za osobe starije od 18 godina koje su. U
                    Nagradnom konkursu učestvuju svi ukusi Ultra Energy limenki od 0,25L i 0,5L.
                    Mehanika učešća podrazumeva kupovinu minimum jedne Ultra Energy limenke uz
                    fiskalni račun plaćen u Mega Maxi, Maxi i Shop&Go prodavnicama sa PFR kodom,
                    kako je opisano na web stranici www.ultrachallenge.rs. Glavna nagrada je
                    zimovanje na Kopaoniku uz kurs skijanja i sankanja, iPhone 15 i oprema za
                    skijanje. Za sve detalje o nagradama i uslovima i odredbama nagradnog konkursa
                    posetite web stranicu www.ultrachallenge.rs. Fotografije poklona su ilustrativnog
                    karaktera. Pažljivo pročitajte pravila nagradnog konkursa objavljena na web
                    stranici.
                  </h2>
                </div>
                {/* <div className="step-container">
                  <button
                    className="quiz-button"
                    onClick={() => navigate('/prijava')} // Navigacija na novu rutu
                  >
                    <span className="pokreni-text">POKRENI</span>
                    <span className="kviz-text">KVIZ</span>
                  </button>
                </div> */}
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </section>
  );
};

export default BottomSection;

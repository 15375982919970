import React, { useState } from "react";
// import sr from "moment/locale/sr";
// @ts-ignore
import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "../Table.css";
import { isInclusivelyAfterDay, isInclusivelyBeforeDay } from "../helpers/isInclusively";
import moment from "moment";

// minDate - 2021-10-31 !!!!!
const minDate = moment("2024-12-15");
const maxDate = moment();

interface DatePickerProps {
  setDate: any;
}

const DatePicker: React.FC<DatePickerProps> = (props) => {
  const [createdAt, setCreatedAt] = useState<moment.Moment>(
    moment().subtract(1, "days")
  );
  const [calendarFocused, setCalendarFocused] = useState<boolean>(false);

  const onDateChange = (createdAt: moment.Moment | null) => {
    if (createdAt) {
      setCreatedAt(createdAt);
      props.setDate(createdAt);
    }
  };

  const onFocusChange = ({ focused }: { focused: boolean }) => {
    setCalendarFocused(focused);
  };

  return (
    <div className="date_picker">
      <SingleDatePicker
        date={createdAt}
        onDateChange={onDateChange}
        focused={calendarFocused}
        onFocusChange={onFocusChange}
        numberOfMonths={1}
        isOutsideRange={(day: any) =>
          isInclusivelyBeforeDay(day, minDate) ||
          isInclusivelyAfterDay(day, maxDate)
        }
        hideKeyboardShortcutsPanel={true}
        readOnly={true}
        displayFormat="DD-MM-YYYY"
        // locale={sr}
      />
    </div>
  );
};

export default DatePicker;

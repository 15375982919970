import React, { useState, useEffect } from 'react';
import { Navbar, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../App.scss';

const FooterBar: React.FC = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    // Proverava širinu ekrana
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 575); // Mobilna verzija za ekrane ≤575px
    };

    handleResize(); // Inicijalna provera
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Navbar fixed="bottom" className="footer-bar">
      <Container className="d-flex justify-content-between align-items-center">
        {!isMobile && (
          <img
            src="../button-kviz-desktop.png" // Desktop verzija slike
            alt="Footer Desktop Background"
            className="footer-image"
          />
        )}

        {/* Dugme sa različitim sadržajem za mobilnu i desktop verziju */}
        <button
          className="quiz-button"
          onClick={() => navigate('/kraj')}
        >
          {isMobile ? (
            <span className="pokreni-text">POKRENI KVIZ</span>
          ) : (
            <>
              <span className="pokreni-text">POKRENI</span>
              <span className="kviz-text">KVIZ</span>
            </>
          )}
        </button>
      </Container>
    </Navbar>
  );
};

export default FooterBar;

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../App.scss';

const MechanismSection: React.FC = () => {
  // const [isVideoVisible, setIsVideoVisible] = useState(false);

  // const handleThumbnailClick = () => {
  //   setIsVideoVisible(true);
  // };

  return (
    <section id="mehanizam" className="mechanism-section py-5 text-white">
      <Container>
        <Row className="mb-4 d-none d-md-flex">
          <Col>
          <h2 className="font-title">
            ZGRABI<span className="ultra-text" style={{ marginLeft: '1%' }}>ULTRA</span>
          </h2>
          <h2 className="font-title">
            NAGRADE
          </h2>
          </Col>
        </Row>
        <div>
        <Row>
          <Col lg={6} className="mt4 mb-lg-0 d-flex flex-column align-items-center">
          <h2 className="font-dnvene-glavne font-left">
            DNEVNE
          </h2>
          <h2 className="font-dnvene-glavne font-left">
          <span className="ultra-text2">NAGRADE</span>
          </h2>
            <img src="../dnevne-nagrade.png" alt="Rosa Bottles" className="img-fluid img-fluid-l mt5 mb-1" />
          </Col>
          <Col lg={6} className="mt4 mb-lg-0 d-flex flex-column align-items-center">
          <h2 className="font-dnvene-glavne font-right">
            GLAVNE
          </h2>
          <h2 className="font-dnvene-glavne font-right">
          <span className="ultra-text2">NAGRADE</span>
          </h2>
            <img src="../glavne-nagrade2.png" alt="Rosa Bottles" className="img-fluid img-fluid-r mt5 mb-1" />
          </Col>
        </Row>
        </div>
      </Container>
    </section>
  );
};

export default MechanismSection;

import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import moment from 'moment';
import '../App.scss';
import getWinnersWeekly from '../helpers/getWinnersWeekly';
import getWinners from '../helpers/getWinners';
import DatePicker from './DatePicker';

interface Winner {
  phone: string;
  award: string;
}

const WinnersSection: React.FC = () => {
  const [selectedView, setSelectedView] = useState<'daily' | 'weekly'>('daily');
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(
    moment().subtract(1, 'days') // Inicijalno postavljanje na jučerašnji datum
  );
  const [winners, setWinners] = useState<Winner[]>([]);
  const [message, setMessage] = useState<string>("");

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 575);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 575);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchWinners = async () => {
      let response = [];
      if (selectedView === 'weekly') {
        // Poziv API-ja za glavne nagrade sa hardkodovanim datumom
        response = await getWinnersWeekly(1); // Datum za glavne nagrade podešen u helpers-u
      } else if (selectedView === 'daily' && selectedDate) {
        const formattedDate = selectedDate.format('YYYYMMDD');
        response = await getWinners(formattedDate);
      }

      if (response.length === 0) {
        setMessage("Nema dobitnika za odabrani prikaz");
        setWinners([]);
      } else {
        setMessage("");
        setWinners(response);
      }
    };

    fetchWinners();
  }, [selectedView, selectedDate]);

  return (
    <section id="lista-dobitnika" className="winners-section">
      <Container>
        <Row className="justify-content-center">
          <Col md={10}>
            <div className="winners-table-wrapper">
              <div className="winners-header">
                <button
                  className="awards-button"
                  onClick={() => setSelectedView('daily')}
                >
                  DNEVNE NAGRADE
                </button>
                {selectedView === 'daily' && (
                  <DatePicker
                    setDate={(date: moment.Moment) => setSelectedDate(date)}
                  />
                )}
                <button
                  className="awards-button"
                  onClick={() => setSelectedView('weekly')}
                >
                  GLAVNE NAGRADE
                </button>
              </div>
              <img
                src={isMobile ? '../mobile-table.png' : '../tabela-ultra.png'}
                alt="Tabela Background"
                className="table-background"
              />
              <div className="winners-table">
                {message ? (
                  <div className="no-winners-message">{message}</div>
                ) : (
                  <table className="custom-font">
                    <tbody>
                      {winners.map((winner, index) => (
                        <tr
                          key={index}
                          className={index % 2 === 0 ? "even" : "odd"}
                        >
                          <td>{index + 1}.</td>
                          <td>{winner.phone}</td>
                          <td>{winner.award}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default WinnersSection;

import React from 'react';
import '../App.scss';

const EndGame: React.FC = () => {
  const socialLinks = [
    {
      icon: 'facebook_ikonica.png',
      alt: 'Facebook',
      link: 'https://www.facebook.com/UltraEnergy',
    },
    {
      icon: 'instagram_ikonica.png',
      alt: 'Instagram',
      link: 'https://www.instagram.com/ultra.energy',
    },
    {
      icon: 'tiktok_ikonica.png',
      alt: 'TikTok',
      link: 'https://www.tiktok.com/@ultra.tiger',
    },
  ];

  return (
    <div className="form-container">
      <div className="end-game">
        <h1 className="end-game-title">NAGRADNI KONKURS JE ZAVRŠEN</h1>
        <div className="follow-us">
          <div className="follow-us-box">
            <p>PRATITE NAS NA</p>
          </div>
          <div className="social-icons">
            {socialLinks.map((social, index) => (
              <a
                key={index}
                href={social.link}
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                <img src={social.icon} alt={social.alt} className="social-icon" />
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EndGame;

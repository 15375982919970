import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

export interface ICheckScoreResponse {
  score: number;
}

const checkScoreAPI = async (phone: string): Promise<ICheckScoreResponse | boolean> => {
    try {
      const config: AxiosRequestConfig = {
        method: 'POST',
        url: `https://ultrachallenge.rs/api/ultra/check-score-main`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: { phone },
      };
  
      const response: AxiosResponse = await axios(config);
  
      // Izvlačimo rezultat iz `data.vrati_rezultat_main`
      const { data } = response.data;
      const score = parseInt(data.vrati_rezultat_main, 10);
  
      return { score };
    } catch (error) {
      console.error('Došlo je do greške pri pozivu check-score API-ja:', error);
      return false;
    }
  };
  

export default checkScoreAPI;

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavigationBar from './components/NavigationBar';
import MechanismSection from './components/MechanismSection';
import WinnersSection from './components/WinnersSection';
import RulesAndContact from './components/RulesAndContact';
import './App.scss';
import BottomSection from './components/BottomSection';
import FooterBar from './components/FooterBar';
import ScoreChecker from './components/ScoreChecker';
import EndGame from './components/EndGame';


const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <NavigationBar />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <section id="mehanizam">
                  <MechanismSection />
                </section>
                <section id="lista-dobitnika">
                  <WinnersSection />
                </section>
                <section id="pravila-kontakt">
                  <RulesAndContact />
                </section>
                <section id="zapocni-kviz">
                  <BottomSection />
                </section>
                {/* <QuizBottomButton /> */}
                <FooterBar />
              </>
            }
          />
          {/* <Route path="/prijava" element={<CodeForm />} />
          <Route path="/kviz" element={<Quiz />} /> */}
          <Route path="/proveri-skor" element={<ScoreChecker />} />
          <Route path="/kraj" element={<EndGame />} />
        </Routes>
      </div>
    </Router>
  );
};


export default App;
